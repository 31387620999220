    import axiosAuth from '../../../shared/configs/axios-auth'
    import router from '@/router'
    import { dispatch } from 'd3'
    const getDefaultState = () => {
        return {
            trash: null,
            users: [],
            authMessage: "",
            codeSent: null,
            updatingAuth: false,
            accountCreated: null,
            accountCompleted: null,
            loggedIn: false,
            userName: '',
            userMail: '',
            idToken: null,
            userId: null,
            errorCode: null,
            logOutTimer: null,
            resetOk: false,
            verificationSent: false,
            emailVerified: null,
            userInformations: null,
            userCreated: null
        }
    }

    const state = getDefaultState()

    const getters = {
        authMessage: state => {
            return state.authMessage;
        },
        codeSent: state => {
            return state.codeSent;
        },
        accountCreated: state => {
            return state.accountCreated;
        },
        userId: state => {
            return state.userId;
        },
        users: state => {
            return state.users;
        },
        userCreated: state => {
            return state.userCreated;
        },
        accountCompleted: state => {
            return state.accountCompleted;
        },
        emailVerified: state => {
            return state.emailVerified;
        },
        verificationSent: state => {
            return state.verificationSent;
        },
        loggedIn: state => {
            return state.loggedIn;
        },
        userName: state => {
            return state.userName;
        },
        userMail: state => {
            return state.userMail;
        },
        updatingAuth: state => {
            return state.updatingAuth;
        },
        authError: state => {
            return state.errorCode;
        },
        logOutTimer: state => {
            return state.logOutTimer;
        },
        resetOk: state => {
            return state.resetOk;
        },
        idToken: state => {
            return state.idToken
        },
        userInformations: state => {
            return state.userInformations
        },
    }

    const mutations = {
        resetState(state) {
            Object.assign(state, getDefaultState())
        },
        authMessage: (state, payload) => {
            state.authMessage = payload
        },
        trash: (state, payload) => {
            state.trash = payload
        },
        users: (state, payload) => {
            state.users = payload
        },
        userCreated: (state, payload) => {
            state.userCreated = payload
        },
        updatingAuth: (state, payload) => {
            state.updatingAuth = payload
        },
        codeSent: (state, payload) => {
            state.codeSent = payload
        },
        accountCreated: (state, bool) => {
            state.accountCreated = bool
        },
        accountCompleted: (state, bool) => {
            state.accountCompleted = bool
        },
        emailVerified: (state, bool) => {
            state.emailVerified = bool
        },
        verificationSent: (state, bool) => {
            state.verificationSent = bool
        },
        loggedIn: (state, bool) => {
            state.loggedIn = bool;
        },
        resetOk: (state, bool) => {
            state.resetOk = bool
        },
        logOut: (state) => {
            state.loggedIn = false;
            state.userName = null
        },
        userName: (state, payload) => {
            state.userName = payload
        },
        authUser(state, userData) {
            state.idToken = userData.token
            state.userId = userData.userId
            state.userName = userData.userName
            state.loggedIn = true
        },
        authError(state, error) {
            state.errorCode = error
        },
        userInformations(state, error) {
            state.userInformations = error
        },
        clearAuthData(state) {
            state.idToken = null
            state.userName = null
            state.userId = null
            state.logOutTimer = null
            clearInterval(this.interval)
            state.resetOk = false
        }
    }

    const actions = {

        tryAutoLogin({ commit, dispatch }) {
            var token = localStorage.getItem('token')
            if (!token) return;
            if (token) {
                if (token.length > 10) {
                    dispatch('userInformations')
                    state.idToken = token
                    commit('authUser', {
                        token: token
                    })
                    axiosAuth.defaults.headers["Authorization"] = token
                    return
                }
            } else {
                if (localStorage.getItem('darkMode')) {
                    commit('darkMode', !localStorage.getItem('darkMode'))
                }
                dispatch('logout')
            }

        },

        signup({ commit, dispatch }, authData) {
            commit('accountCreated', null)
            commit('authError', null)
            commit('authMessage', '')
            commit('updatingAuth', true)
            axiosAuth.post('/authnews/register', authData)
                .then(
                    res => {
                        console.log(res)
                        commit('trash', res)
                        commit('updatingAuth', false)
                        commit('accountCreated', true)
                        dispatch('loginModal', authData)
                    }
                )
                .catch(
                    error => {
                        console.log(error.response)
                        commit('authMessage', error.response.data)
                        commit('accountCreated', false)
                        commit('authError', error.response.status)
                        commit('updatingAuth', false)
                    }
                )
        },

        verifyEmail({ commit, dispatch }, authData) {
            commit('authError', null)
            commit('authMessage', '')
            commit('updatingAuth', true)
            commit('accountCompleted', null)
            axiosAuth.post('/authnews/verifyemailapp', authData)
                .then(
                    res => {
                        console.log(res)
                        commit('trash', res)
                        commit('updatingAuth', false)
                        commit('accountCompleted', true)
                        dispatch('userInformations')
                    }
                )
                .catch(
                    error => {
                        console.log(error.response)
                        commit('authMessage', error.response.data)
                        commit('authError', error.response.status)
                        commit('updatingAuth', false)
                        commit('accountCompleted', false)
                    }
                )
        },

        resendCode({ commit, dispatch }) {
            commit('authError', null)
            commit('authMessage', '')
            commit('updatingAuth', true)
            commit('codeSent', null)
            axiosAuth.post('/authnews/verificationcode')
                .then(
                    res => {
                        console.log(res)
                        commit('trash', res)
                        commit('updatingAuth', false)
                        commit('codeSent', true)
                        dispatch('userInformations')
                    }
                )
                .catch(
                    error => {
                        console.log(error.response)
                        commit('authMessage', error.response.data)
                        commit('authError', error.response.status)
                        commit('updatingAuth', false)
                        commit('codeSent', false)
                    }
                )
        },

        createAccount({ commit, dispatch }, payload) {
            commit('accountCreated', null)
            commit('authError', null)
            commit('authMessage', '')
            commit('updating', true)
            axiosAuth.post('/authnews/verifyemail', payload)
                .then(
                    res => {
                        commit('trash', res)
                        commit('updating', false)
                        commit('accountCreated', true)
                        localStorage.setItem('token', res.data.token)
                        axiosAuth.defaults.headers["Authorization"] = res.data.token;

                        dispatch('userInformations')
                        commit('updating', false)
                        router.push('/profil').catch(() => {})
                    }
                )
                .catch(
                    error => {
                        console.log(error.response)
                        commit('authMessage', error.response.data)
                        commit('accountCreated', false)
                        commit('authError', error.response.status)
                        commit('updating', false)
                    }
                )
        },

        createAccount({ commit, dispatch }, payload) {
            commit('accountCreated', null)
            commit('authError', null)
            commit('authMessage', '')
            commit('updating', true)
            axiosAuth.post('/authnews/verifyemail', payload)
                .then(
                    res => {
                        commit('trash', res)
                        commit('updating', false)
                        commit('accountCreated', true)
                        localStorage.setItem('token', res.data.token)
                        axiosAuth.defaults.headers["Authorization"] = res.data.token;

                        dispatch('userInformations')
                        commit('updating', false)
                        router.push('/profil').catch(() => {})
                    }
                )
                .catch(
                    error => {
                        console.log(error.response)
                        commit('authMessage', error.response.data)
                        commit('accountCreated', false)
                        commit('authError', error.response.status)
                        commit('updating', false)
                    }
                )
        },

        resetPassword({ commit }, authData) {
            commit('accountCreated', null)
            commit('authError', null)
            commit('authMessage', '')
            commit('updating', true)
            axiosAuth.post('/authnews/resetpassword', {
                    email: authData.email,
                })
                .then(
                    res => {
                        commit('trash', res)
                        commit('updating', false)
                    }
                )
                .catch(
                    error => {
                        console.log(error.response)
                        commit('authMessage', error.response.data)
                        commit('authError', error.response.status)
                        commit('updating', false)
                    }
                )
        },

        userInformations({ commit, dispatch }) {
            commit('authError', null)
            commit('authMessage', '')
            commit('updating', true)
            axiosAuth.get('/authnews/me')
                .then(
                    res => {
                        commit('updating', false)
                        console.log(res)
                        if (res.data.admin) {
                            localStorage.setItem('admin', true);
                        } else {
                            localStorage.removeItem('admin');
                        }
                        // if (!res.data.admin) {
                        //     dispatch('logout', { message: 'notAuthorized' })
                        // }
                        commit('userInformations', res.data)
                            //commit('loggedIn', true)

                    }
                )
                .catch(
                    error => {
                        console.log(error)
                        commit('authMessage', error.response.data)
                        commit('authError', error.response.status)
                        commit('updating', false)
                            // dispatch('logout')
                    }
                )
        },

        updateUserInformations: ({ commit, dispatch }, payload) => {
            commit('updating', true)
            axiosAuth.patch('/authnews/me', payload)
                .then(
                    res => {
                        console.log(res)
                        commit('updating', false)
                        dispatch('userInformations')
                    }
                )
                .catch(
                    error => {
                        console.log(error.response)
                        commit('updating', false)
                    }
                )
        },

        login({ commit, dispatch }, authData) {
            commit('authError', null)
            commit('authMessage', '')
            commit('updating', true)
            axiosAuth.post('/authnews/login', {
                    email: authData.email,
                    password: authData.password
                })
                .then(
                    res => {
                        commit('loggedIn', true)
                        commit('resetState')
                        state.idToken = res.data.token
                        commit('authUser', {
                            token: res.data.token,
                            userId: res.data.id,
                            userMail: res.data.email,
                            userName: res.data.name
                        })

                        localStorage.setItem('token', res.data.token)
                        axiosAuth.defaults.headers["Authorization"] = res.data.token;

                        dispatch('userInformations')
                        commit('updating', false)
                        if (!authData.noredirect) {
                            router.push('/').catch(() => {})
                        }

                    }
                )
                .catch(
                    error => {
                        console.log(error.response)
                        commit('updating', false)
                        commit('authError', error.response.status)
                        commit('authMessage', error.response.data)
                    }
                )
        },

        loginModal({ commit, dispatch }, authData) {
            commit('authError', null)
            commit('authMessage', '')
            commit('updating', true)
            axiosAuth.post('/authnews/login', {
                    email: authData.email,
                    password: authData.password
                })
                .then(
                    res => {
                        commit('loggedIn', true)
                            // commit('resetState')
                        state.idToken = res.data.token
                        commit('authUser', {
                            token: res.data.token,
                            userId: res.data.id,
                            userMail: res.data.email,
                            userName: res.data.name
                        })

                        localStorage.setItem('token', res.data.token)
                        axiosAuth.defaults.headers["Authorization"] = res.data.token;

                        dispatch('userInformations')
                        commit('updating', false)
                            // if (!authData.noredirect) {
                            //     router.push('/').catch(() => {})
                            // }

                    }
                )
                .catch(
                    error => {
                        console.log(error.response)
                        commit('updating', false)
                        commit('authError', error.response.status)
                        commit('authMessage', error.response.data)
                    }
                )
        },

        logout({ commit }, data) {
            localStorage.removeItem('token');
            localStorage.removeItem('admin');
            commit('resetState')
            commit('loggedIn', false)
            commit('updating', false)
            if (!data) {
                router.push('/').catch(err => console.log(err.what))
            } else {
                router.push('/' + data.message).catch(err => console.log(err.what))
            }

        },

        logoutModal({ commit }, data) {
            localStorage.removeItem('token');
            commit('userInformations', null)
            commit('loggedIn', false)
            commit('updating', false)
        },


    }

    export default {
        state,
        getters,
        mutations,
        actions
    }