/*
=========================================================
* Vuetify Material Dashboard 2 PRO - v3.0.0
=========================================================

* Product Page:  https://www.creative-tim.com/product/vuetify-material-dashboard-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import DashboardPlugin from "./plugins/dashboard-plugin";
import { store } from '@/store'
import Vuex from 'vuex'
import LoadingOverlay from '@/shared/animations/loadingOverlay.vue'
Vue.component('loading-overlay', LoadingOverlay)
import coreData from "@/mixins/coreDataMixin";

Vue.config.productionTip = false;

// Photoswipe Gallery
import Photoswipe from "vue-pswipe";
Vue.use(Photoswipe);
Vue.use(Vuex);
// plugin setup
Vue.use(DashboardPlugin);

const moment = require('moment')
require('moment/locale/de')

Vue.use(require('vue-moment'), {
    moment
})

router.beforeEach((to, from, next) => {
    console.log(to.path)
    if (to.path.includes('admin')) {

        if ((!localStorage.getItem('token') || !localStorage.getItem('admin')) && to.path !== `/admin/login`) {
            return next(`/`)
        } else {
            return next()
        }
        if (store.getters.userInformations) {
            if (store.getters.userInformations.admin) {
                next()
            }
        }
        return next('/')
    }
    // else if (to.path == '/invoices' || to.path == '/profile') {
    //     if (store.getters.userInformations) {
    //         return next()
    //     }
    //     return next('/')
    // } 
    else {
        return next()
    }

})

new Vue({
    mixins: [coreData],
    store,
    router,
    vuetify,
    render: (h) => h(App),
}).$mount("#app");
11111